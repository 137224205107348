<template>
    <v-card v-if="packageData">
        <v-toolbar @click="togglePackageCollapse" class="cursor-pointer">
            <v-toolbar-title>Pachet {{ packageIndex + 1 }}</v-toolbar-title>
            <v-toolbar-items>
                <v-btn
                    :disabled="!userPermissions.returnsManagement.canDeletePackage || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                    color="error" icon
                    @click.stop="$emit('remove-package', packageIndex)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-expand-transition>
            <v-card-text v-if="isPackageExpanded">
                <!-- Package Information -->
                <v-row>
                    <v-col cols="12" sm="4" md="2">
                        <v-text-field
                            label="Lungime"
                            v-model="packageData.length"
                            variant="outlined"
                            density="compact"
                            hide-details
                            suffix="cm"
                            :readonly="!userPermissions.returnsManagement.canEditPackageLength || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                            @update:modelValue="emitPackageUpdate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-text-field
                            label="Lățime"
                            v-model="packageData.width"
                            variant="outlined"
                            density="compact"
                            hide-details
                            suffix="cm"
                            :readonly="!userPermissions.returnsManagement.canEditPackageWidth || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                            @update:modelValue="emitPackageUpdate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2">
                        <v-text-field
                            label="Înălțime"
                            v-model="packageData.height"
                            variant="outlined"
                            density="compact"
                            hide-details
                            suffix="cm"
                            :readonly="!userPermissions.returnsManagement.canEditPackageHeight || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                            @update:modelValue="emitPackageUpdate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-text-field
                            label="Greutate"
                            v-model="packageData.weight"
                            variant="outlined"
                            density="compact"
                            suffix="kg"
                            :readonly="!userPermissions.returnsManagement.canEditPackageWeight || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                            @update:modelValue="emitPackageUpdate"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                            label="Tip pachet"
                            v-model="packageData.type"
                            variant="outlined"
                            density="compact"
                            :items="packageTypes"
                            item-title="title"
                            item-value="value"
                            :readonly="!userPermissions.returnsManagement.canEditPackageType || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                            @update:modelValue="emitPackageUpdate"
                        ></v-select>
                    </v-col>
                </v-row>
                <!-- Package Attachments -->
                <v-card class="mt-3">
                    <v-toolbar density="compact">
                        <v-toolbar-title>Fotografii pachet</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <!-- File input for new photos -->
                        <v-file-input
                            v-model="packageData.newPhotos"
                            label="Adaugă fotografii"
                            variant="outlined"
                            density="compact"
                            accept="image/*"
                            multiple
                            :disabled="!userPermissions.returnsManagement.canAddPackageImages || returnData.operated && !userPermissions.returnsManagement.canEditOperatedReturn"
                            @update:modelValue="handleNewPhotoAdded"
                        ></v-file-input>
                        <v-row>
                            <!-- Display existing attachments -->
                            <v-col
                                v-for="(attachment, index) in packageData.attachments"
                                :key="'existing-' + attachment.id"
                                cols="12" sm="6" md="4"
                            >
                                <v-card>
                                    <v-toolbar density="compact"
                                               :color="attachment?.added_by?.includes('acquisitionsOnly') ? 'warning' : ''">
                                        <v-toolbar-title>
                                            Imagine {{ index + 1 }}
                                            <span v-if="attachment?.added_by?.includes('acquisitionsOnly')"
                                                  style="font-size: 12px;">
                                                (Adăugat de Achiziții)
                                              </span>
                                        </v-toolbar-title>
                                        <v-toolbar-items>
                                            <v-btn
                                                :disabled="!canDeleteAttachment(attachment)"
                                                icon
                                                @click="removeExistingAttachment(attachment, index)"
                                                color="red"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-img
                                            @click="previewImage(getAttachmentUrl(attachment))"
                                            :src="getAttachmentUrl(attachment)"
                                            style="height: 200px;"
                                            aspect-ratio="1"
                                            class="cursor-pointer"
                                        ></v-img>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <!-- Display new photos -->
                            <v-col
                                v-for="(photo, index) in packageData.newPhotos"
                                :key="'new-' + index"
                                cols="12" sm="6" md="4"
                            >
                                <v-card>
                                    <v-toolbar density="compact"
                                               :color="photo.added_by.includes('acquisitionsOnly') ? 'danger' : ''">
                                        <v-toolbar-title>
                                            Imagine nouă
                                        </v-toolbar-title>
                                        <v-toolbar-items>
                                            <v-btn
                                                icon
                                                @click="removeNewPhoto(index)"
                                                color="red"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </v-toolbar-items>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-img
                                            @click="previewImage(getPhotoUrl(photo))"
                                            :src="getPhotoUrl(photo)"
                                            style="height: 200px;"
                                            aspect-ratio="1"
                                            class="cursor-pointer"
                                        ></v-img>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-card-text>
        </v-expand-transition>
        <!-- Dialog for deleting attachment -->
        <v-dialog v-model="showDeleteAttachmentDialog" persistent max-width="500px">
            <v-card>
                <v-card-title class="headline">Confirmare Ștergere Fotografie</v-card-title>
                <v-card-text>
                    Ești sigur că dorești să ștergi această fotografie?
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                    <v-btn color="grey" text @click="cancelDeleteAttachment">Anulează</v-btn>
                    <v-btn color="danger" text @click="confirmDeleteAttachment">Șterge</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Image Preview Dialog -->
        <v-dialog v-model="showImagePreview" max-width="75%">
            <v-card>
                <v-card-title class="headline">
                    Preview Imagine
                </v-card-title>
                <v-card-text>
                    <v-img :src="selectedImage" max-height="750px" contain></v-img>
                </v-card-text>
                <v-card-actions class="d-flex justify-content-end">
                    <v-btn text @click="showImagePreview = false">Închide</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import {cloneDeep} from "lodash";
import {mapGetters} from "vuex";

export default {
    name: "ReturnPackageComponent",
    props: {
        packageItem: {
            type: Object,
            required: true,
        },
        packageIndex: {
            type: Number,
            required: true,
        },
        returnData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            packageData: null,
            isPackageExpanded: true,
            showDeleteAttachmentDialog: false,
            attachmentToDelete: null,
            attachmentToDeleteIndex: null,
            showImagePreview: false,
            selectedImage: null,
            packageTypes: [
                {title: "Palet", value: "Palet"},
                {title: "Colet", value: "Colet"},
            ],
        };
    },
    emits: ['update-package', 'remove-package'],
    computed: {
        ...mapGetters(['userPermissions', 'userRole']),
    },
    mounted() {
        this.packageData = cloneDeep(this.packageItem);
        if (!this.packageData.attachmentsToDelete) {
            this.packageData.attachmentsToDelete = [];
        }
    },
    methods: {
        canDeleteAttachment(attachment) {
            if (this.userPermissions.isSuperuser) {
                return true;
            }
            if (this.userPermissions.returnsManagement.canRemovedPackageImages) {
                return !attachment.added_by || this.userRole.includes(attachment.added_by);
            }
            return attachment.added_by && this.userRole.includes(attachment.added_by);
        },
        handleNewPhotoAdded(newPhotos) {
            if (!newPhotos) return;

            // Ensure added_by is set for each newly added photo
            this.packageData.newPhotos = newPhotos.map(photo => {
                // Instead of modifying the File object, store it under a key (e.g., file)
                return {file: photo, added_by: this.userRole};
            });

            this.emitPackageUpdate(); // Ensure parent gets the update
        },
        /**
         * Toggles the expansion/collapse state of the package details.
         */
        togglePackageCollapse() {
            this.isPackageExpanded = !this.isPackageExpanded;
        },
        /**
         * Emits an update event to the parent component with the current package data.
         */
        emitPackageUpdate() {
            this.$emit("update-package", {
                packageIndex: this.packageIndex,
                packageData: this.packageData,
            });
        },
        /**
         * Opens the image preview dialog for a given image URL.
         *
         * @param {string} imageUrl - The URL of the image to preview.
         */
        previewImage(imageUrl) {
            this.selectedImage = imageUrl;
            this.showImagePreview = true;
        },
        /**
         * Retrieves the URL for a given attachment.
         *
         * @param {Object} attachment - The attachment object containing file_url.
         * @returns {string} - The URL of the attachment.
         */
        getAttachmentUrl(attachment) {
            return attachment.file_url || '';
        },
        /**
         * Retrieves the URL for a given photo (either a File object, a string URL, or an object with a url property).
         *
         * @returns {string} - A temporary URL or the original URL of the photo.
         * @param photoWrapper
         */
        getPhotoUrl(photoWrapper) {
            // Check if the photo is wrapped in an object
            if (photoWrapper && photoWrapper.file instanceof File) {
                return URL.createObjectURL(photoWrapper.file);
            } else if (typeof photoWrapper === 'string') {
                return photoWrapper;
            } else if (photoWrapper && photoWrapper.url) {
                return photoWrapper.url;
            } else {
                return '';
            }
        },
        /**
         * Initiates the deletion process for an existing attachment by showing a confirmation dialog.
         *
         * @param {Object} attachment - The attachment object to delete.
         * @param {number} index - The index of the attachment in the attachments array.
         */
        removeExistingAttachment(attachment, index) {
            this.attachmentToDelete = attachment;
            this.attachmentToDeleteIndex = index;
            this.showDeleteAttachmentDialog = true;
        },
        /**
         * Cancels the deletion of an attachment by resetting related state and closing the confirmation dialog.
         */
        cancelDeleteAttachment() {
            this.attachmentToDelete = null;
            this.attachmentToDeleteIndex = null;
            this.showDeleteAttachmentDialog = false;
        },
        /**
         * Confirms the deletion of the currently selected attachment, removes it from the attachments array,
         * and emits an update event to the parent component.
         */
        confirmDeleteAttachment() {
            // Add attachment to attachmentsToDelete array
            this.packageData.attachmentsToDelete.push(this.attachmentToDelete);
            // Remove attachment from packageData.attachments array
            this.packageData.attachments.splice(this.attachmentToDeleteIndex, 1);
            // Emit update to parent
            this.emitPackageUpdate();
            // Reset the deletion variables
            this.cancelDeleteAttachment();
        },
        /**
         * Removes a newly added photo (not yet saved to the server) from the package data,
         * and emits an update event to the parent component.
         *
         * @param {number} index - The index of the new photo in the newPhotos array.
         */
        removeNewPhoto(index) {
            this.packageData.newPhotos.splice(index, 1);
            this.emitPackageUpdate();
        },
    },
    watch: {
        packageItem: {
            handler(newVal) {
                this.packageData = cloneDeep(newVal);
                if (!this.packageData.attachmentsToDelete) {
                    this.packageData.attachmentsToDelete = [];
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
/* Add any necessary styles */
</style>
