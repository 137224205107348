import {createRouter, createWebHistory} from 'vue-router'
import UserLogin from '@/views/UserLogin.vue'
import MainLayout from "@/views/MainLayout.vue";
import ClientCreate from "@/views/ClientCreate.vue";
import ClientsList from "@/views/ClientsList.vue";
import ClientDetails from "@/views/ClientDetails.vue";
import OfferVMC from "@/views/OfferVMC.vue";
import IntermediateList from "@/views/IntermediateList.vue";
import OfferSimple from "@/views/OfferSimple.vue";
import PriceList from "@/views/PriceList.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import UserLogout from "@/views/UserLogout.vue";
import OfferPlumbing from "@/views/OfferPlumbing.vue";
import OfferTechnicalRoom from "@/views/OfferTechnicalRoom.vue";
import OfferCeiling from "@/views/OfferCeiling.vue";
import OfferWalls from "@/views/OfferWalls.vue";
import OfferAutomation from "@/views/OfferAutomation.vue";
import OfferFanCoilUnit from "@/views/OfferFanCoilUnit.vue";
import OfferThermalChannel from "@/views/OfferThermalChannel.vue";
import OfferPDC from "@/views/OfferPDC.vue";
import OffersSummary from "@/views/OffersSummary.vue";
import OfferVacuum from "@/views/OfferVacuum.vue";
import OfferServices from "@/views/OfferServices.vue";
import ClientsPrecontracts from "@/views/ClientsPrecontracts.vue";
import GreenHouseClientForm from "@/views/GreenHouseClientForm.vue";
import PDFViewer from "@/views/PDFViewer.vue";
import ReturnsTab from "@/views/ReturnsTab.vue";
import ReturnCreate from "@/views/ReturnCreate.vue";
import NotFound from "@/views/NotFound.vue";
import GreenHouseSolarClientsList from "@/views/GreenHouseSolarClientsList.vue";
import GreenHouseSolarClientCreate from "@/views/GreenHouseSolarClientCreate.vue";
import store from "@/store";

const routes = [
    {
        path: '/home',
        name: 'Home',
        component: MainLayout,
        meta: {
            requiresAuth: true // This route does not require authentication
        },
        children: [
            {
                path: '',
                name: 'ClientList',
                component: ClientsList,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                    ],
                },
            },
            {
                path: '/intermediates',
                name: 'IntermediateList',
                component: IntermediateList,
                meta: {
                    breadcrumbs: [
                        {label: 'Intermediari', path: '/home'},
                    ],
                },
            },
            {
                path: '/priceList',
                name: 'PriceList',
                component: PriceList,
                meta: {
                    breadcrumbs: [
                        {label: 'Listă de prețuri', path: '/home'},
                    ],
                    requiresSuperuser: true,
                },
            },
            {
                path: '/returns-tab',
                name: 'ReturnsTab',
                component: ReturnsTab,
                meta: {
                    breadcrumbs: [
                        {label: 'Tabel retururi', path: '/home'},
                    ],
                    requiresSuperuser: false,
                },
            },
            {
                path: '/clients-precontracts',
                name: 'ClientsPrecontracts',
                component: ClientsPrecontracts,
                meta: {
                    breadcrumbs: [
                        {label: 'RePower', path: '/home'},
                    ],
                    requiresSuperuser: false,
                },
            },
            {
                path: '/green-house-client-solar-tab',
                name: 'GreenHouseSolarClientsList',
                component: GreenHouseSolarClientsList,
                meta: {
                    breadcrumbs: [
                        {label: 'Casa verde', path: '/home'},
                    ],
                    requiresSuperuser: false,
                },
            },
            {
                path: '/green-house-client-solar-create/:clientId?',
                name: 'GreenHouseSolarClientCreate',
                component: GreenHouseSolarClientCreate,
                meta: {
                    breadcrumbs: [
                        {label: 'Casa verde', path: '/green-house-client-solar-tab'},
                        {label: 'Client casa verde', path: '/'},
                    ],
                    requiresSuperuser: false,
                },
                props: true,
            },
            {
                path: '/green-house-client/:clientId?',
                name: 'GreenHouseClientForm',
                component: GreenHouseClientForm,
                meta: {
                    breadcrumbs: [
                        {label: 'RePower', path: '/home'},
                    ],
                    requiresSuperuser: false,
                },
                props: true,
            },
            {
                path: '/client',
                name: 'Client',
                component: ClientCreate,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Creare client', path: '/client'},
                    ],
                },
            },
            {
                path: '/simple-offer/:clientId/:locationId/:offerId?',
                name: 'OfferSimple',
                component: OfferSimple,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferSimple') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Simplă', path: '/'},
                    ],
                },
            },
            {
                path: '/plumbing-offer/:clientId/:locationId/:offerId?',
                name: 'OfferPlumbing',
                component: OfferPlumbing,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferPlumbing') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Sanitare', path: '/'},
                    ],
                },
            },
            {
                path: '/technical-room-offer/:clientId/:locationId/:offerId?',
                name: 'OfferTechnicalRoom',
                component: OfferTechnicalRoom,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferTechnicalRoom') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Camera Tehnica', path: '/'},
                    ],
                },
            },
            {
                path: '/ceiling-offer/:clientId/:locationId/:offerId?',
                name: 'OfferCeiling',
                component: OfferCeiling,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferCeiling') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Tavan', path: '/'},
                    ],
                },
            },
            {
                path: '/walls-offer/:clientId/:locationId/:offerId?',
                name: 'OfferWalls',
                component: OfferWalls,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferWalls') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Pereți', path: '/'},
                    ],
                },
            },
            {
                path: '/automation-offer/:clientId/:locationId/:offerId?',
                name: 'OfferAutomation',
                component: OfferAutomation,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferAutomation') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Automatizare', path: '/'},
                    ],
                },
            },
            {
                path: '/fan-coil-unit-offer/:clientId/:locationId/:offerId?',
                name: 'OfferFanCoilUnit',
                component: OfferFanCoilUnit,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferFanCoilUnit') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Ventiloconvectoare', path: '/'},
                    ],
                },
            },
            {
                path: '/thermal-channel-offer/:clientId/:locationId/:offerId?',
                name: 'OfferThermalChannel',
                component: OfferThermalChannel,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferThermalChannel') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Canal Termic', path: '/'},
                    ],
                },
            },
            {
                path: '/heat-pump-offer/:clientId/:locationId/:offerId?',
                name: 'OfferPDC',
                component: OfferPDC,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferPDC') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă Pompe de Căldură', path: '/'},
                    ],
                },
            },
            {
                path: "/client-details/:id",
                name: "ClientDetails",
                component: ClientDetails,
                props: true,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                    ],
                },
            },
            {
                path: '/pdf-contract-viewer/:linkUuid',
                name: 'PDFViewer',
                component: PDFViewer,
                meta: {
                    requiresAuth: false
                },
                props: true, // Enables passing route params as props
            },
            {
                path: "/offers-summary/:id/:locationId?",
                name: "OffersSummary",
                component: OffersSummary,
                props: true,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Centralizator oferte', path: '/'},
                    ],
                },
            },
            {
                path: "/vacuum-offer/:clientId/:locationId?/:offerId?",
                name: "OfferVacuum",
                component: OfferVacuum,
                props: true,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă aspirare', path: '/'},
                    ],
                },
            },
            {
                path: "/services-offer/:clientId/:locationId?/:offerId?",
                name: "OfferServices",
                component: OfferServices,
                props: true,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: 'Ofertă servicii', path: '/'},
                    ],
                },
            },
            {
                path: "/create-return/:returnId?",
                name: "ReturnCreate",
                component: ReturnCreate,
                props: true,
                meta: {
                    breadcrumbs: [
                        {label: 'Retururi', path: '/returns-tab'},
                        {label: 'Creare retur', path: '/'},
                    ],
                    requiresAuth: true,
                },
            },
            {
                path: '/vmc-offer/:clientId/:locationId/:offerId?/:childLocation?/',
                name: 'OfferVMC',
                component: OfferVMC,
                beforeRouteUpdate(to, from, next) {
                    // Force reload logic
                    if (to.name === 'OfferSimple') {
                        next(vm => {
                            vm.$forceUpdate(); // Force the component to update
                        });
                    } else {
                        next();
                    }
                },
                meta: {
                    breadcrumbs: (route) => [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Detalii client', path: '/client-details'},
                        {label: `Ofertă VMC ${route.params.offerId}`, path: '/'},
                    ],
                },
            },
            {
                path: "/:pathMatch(.*)*",
                component: PageNotFound,
                meta: {
                    breadcrumbs: [
                        {label: 'Clienți', path: '/home'},
                        {label: 'Pagina nu a fost găsită', path: '/'}
                    ],
                },
            },
        ],
    },
    {
        path: '/client',
        name: 'ClientCreate',
        component: ClientCreate,
        meta: {
            requiresAuth: true // This route does not require authentication
        }
    },
    {
        path: '/clients',
        name: 'ClientsList',
        component: ClientsList,
        meta: {
            requiresAuth: true // This route does not require authentication
        }
    },
    {
        path: '/',
        name: 'UserLogin',
        component: UserLogin,
        meta: {
            requiresAuth: false // This route does not require authentication
        }
    },
    {
        path: '/login',
        name: 'CasaVerdeLogin',
        component: UserLogin, // Same component, different path
        meta: {
            requiresAuth: false // This route does not require authentication
        }
    },
    {
        path: '/logout',
        name: 'UserLogout',
        component: UserLogout,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        meta: {
            requiresAuth: false
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            };
        } else if (savedPosition) {
            return savedPosition;
        } else if (isSavedLocationOrOfferRoute(to, from)) {
            return false;
        } else {
            return {top: 0};
        }
    },
})
router.beforeEach(async (to, from, next) => {
    // Bypass for PDFViewer route
    if (to.name === 'PDFViewer') {
        return next()
    }

    // If the user is external repower user ...
    const isExternalRepowerUser = JSON.parse(sessionStorage.getItem('isExternalRepowerUser')) || false
    if (isExternalRepowerUser) {
        const externalRepowerUsersAllowedRoutes = ['ClientsPrecontracts', 'GreenHouseClientForm', 'UserLogin']
        if (!externalRepowerUsersAllowedRoutes.includes(to.name)) {
            console.warn(`ExternalRepowerUser: route denied -> ClientsPrecontracts`)
            return next({name: 'ClientsPrecontracts'})
        }
    }

    // Basic checks
    const token = localStorage.getItem('token');
    const isLoggedIn = !!token;
    const authValidated = store.state.authValidated;
    const userPermissionsExist = !!sessionStorage.getItem('userPermissions');

    // If route requires auth but no token => go login
    if (to.matched.some(r => r.meta.requiresAuth) && !isLoggedIn) {
        return next({name: 'UserLogin', query: {redirect: to.fullPath}})
    }

    // If we have a token but haven't validated yet => reauth
    if (isLoggedIn && !authValidated && !userPermissionsExist) {
        try {
            await store.dispatch('tryTokenReauth')
            // If that worked, we have user data. Now continue to the requested route.
        } catch (err) {
            // If token invalid => remove it, go login
            console.error('Invalid token in guard', err)
            localStorage.removeItem('token')
            return next({name: 'UserLogin', query: {redirect: to.fullPath}})
        }
    }

    // If superuser required
    const isSuperuser = JSON.parse(sessionStorage.getItem('superUser')) || false
    if (to.matched.some(r => r.meta.requiresSuperuser) && !isSuperuser) {
        return next({name: 'Home'})
    }

    // else => proceed
    next()
})

/**
 * This method checks whether the router is redirecting towards a route that doesn't require scrollToTop.
 */
const isSavedLocationOrOfferRoute = (to, from) => {
    //Check if the next route is the same as the previous route
    let isSameRoute = to.fullPath === from.fullPath;
    //Check if the base route for both next and previous route contains is vmc-offer component path
    let isSameOfferVMCRoute = to.fullPath.includes('vmc-offer') && from.fullPath.includes('vmc-offer');
    //Check if the next route is a save Offer route path pattern
    let toOfferId = to.params.offerId;
    let isSavedOffer = isSameOfferVMCRoute && to.fullPath.replace(toOfferId + '/', '') === from.fullPath;
    //Check if the next route is a save Location route path pattern
    let fromOfferId = from.params.offerId;
    let isSavedLocation = isSameOfferVMCRoute && from.fullPath.replace(fromOfferId + '/', '') === to.fullPath;
    //Check if the next route is a create new offer route path pattern
    let isNewOffer = isSameOfferVMCRoute && to.fullPath.replace(toOfferId + '/', '') === from.fullPath.replace(fromOfferId + '/', '');

    return isSameRoute || isSavedOffer || isSavedLocation || isNewOffer;
}

export default router
