import {PDC_PRODUCT_TYPES} from "../../src/PDC/pdcProducts.js";

export function createPDCUnitRequirements(units) {
    let PDCUnitsRequirements = [];

    // Filter units where unitType.value is 2
    let filteredUnits = units.filter(unit => unit.selectedUnit && unit.selectedUnit.type === 2);

    // Each unit adds 10 pipes, only count filtered units
    let totalPipes = filteredUnits.length * 10;
    let MYYMCableQuantity = (totalPipes / 2) + 5; // Apply the given formula
    let blankPVCStripQuantity = Math.ceil((totalPipes / 5) / 2);

    let MYYMCable = {
        "type": PDC_PRODUCT_TYPES.CABLU,
        "subtype": "MYYM",
        "quantity": MYYMCableQuantity,
    };

    let blankPVCStrip = {
        "type": PDC_PRODUCT_TYPES.BANDA,
        "subtype": "neadeziva",
        "variant": "PVC",
        "quantity": blankPVCStripQuantity,
    };

    // Add the MYYMCable and blank pvc strip requirement to the PDCUnitsRequirements array
    if (totalPipes) {
        PDCUnitsRequirements.push(MYYMCable);
        PDCUnitsRequirements.push(blankPVCStrip);
    }

    return PDCUnitsRequirements;
}

export const HEAT_EXCHANGE_PRODUCT_REQUIREMENTS = {
    LOW_POWER: [
        {
            "type": PDC_PRODUCT_TYPES.SCHIMBATOR,
            "subtype": "caldura",
            "power": "4-12",
            "quantity": 1,
            "condition": (unit) => unit.default_pump_power >= 4 && unit.default_pump_power <= 12,
        },
        {
            "type": PDC_PRODUCT_TYPES.POMPA,
            "subtype": "sistema",
            "variant": "25-60",
            "quantity": 1,
            "condition": (unit) => unit.default_pump_power >= 4 && unit.default_pump_power <= 12,
        },
        {
            "type": PDC_PRODUCT_TYPES.RACORD,
            "subtype": "pompa",
            "quantity": 1,
        },
    ],
    HIGH_POWER: [
        {
            "type": PDC_PRODUCT_TYPES.SCHIMBATOR,
            "subtype": "caldura",
            "power": "14-18",
            "quantity": 1,
            "condition": (unit) => unit.default_pump_power >= 14 && unit.default_pump_power <= 18,
        },
        {
            "type": PDC_PRODUCT_TYPES.POMPA,
            "subtype": "sistema",
            "variant": "25-70",
            "quantity": 1,
            "condition": (unit) => unit.default_pump_power >= 14 && unit.default_pump_power <= 18,
        },
        {
            "type": PDC_PRODUCT_TYPES.RACORD,
            "subtype": "pompa",
            "quantity": 1,
        },
    ],
}